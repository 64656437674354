exports.components = {
  "component---src-components-blog-blog-detail-js": () => import("./../../../src/components/Blog/BlogDetail.js" /* webpackChunkName: "component---src-components-blog-blog-detail-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-auth-forgot-password-js": () => import("./../../../src/pages/auth/forgot-password.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-js" */),
  "component---src-pages-auth-index-js": () => import("./../../../src/pages/auth/index.js" /* webpackChunkName: "component---src-pages-auth-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-portfolio-three-js": () => import("./../../../src/pages/portfolio/portfolio-three.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-three-js" */),
  "component---src-pages-portfolio-portfolio-two-js": () => import("./../../../src/pages/portfolio/portfolio-two.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-two-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-content-backend-development-js": () => import("./../../../src/pages/services/content/backend-development.js" /* webpackChunkName: "component---src-pages-services-content-backend-development-js" */),
  "component---src-pages-services-content-backend-integration-testing-js": () => import("./../../../src/pages/services/content/backend-integration-testing.js" /* webpackChunkName: "component---src-pages-services-content-backend-integration-testing-js" */),
  "component---src-pages-services-content-devops-services-js": () => import("./../../../src/pages/services/content/devops-services.js" /* webpackChunkName: "component---src-pages-services-content-devops-services-js" */),
  "component---src-pages-services-content-end-to-end-backend-testing-js": () => import("./../../../src/pages/services/content/end-to-end-backend-testing.js" /* webpackChunkName: "component---src-pages-services-content-end-to-end-backend-testing-js" */),
  "component---src-pages-services-content-ongoing-support-js": () => import("./../../../src/pages/services/content/ongoing-support.js" /* webpackChunkName: "component---src-pages-services-content-ongoing-support-js" */),
  "component---src-pages-services-content-web-app-development-js": () => import("./../../../src/pages/services/content/web-app-development.js" /* webpackChunkName: "component---src-pages-services-content-web-app-development-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-feature-template-js": () => import("./../../../src/templates/feature-template.js" /* webpackChunkName: "component---src-templates-feature-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

