/*
 * Stylesheets for the default theme
 */

import "./src/styles/bootstrap.min.css";
import "./src/styles/bootstrap-icons.css";
import "./src/styles/boxicons.min.css";
import "./src/styles/flaticon_doxi.css";
import "swiper/css";
import "swiper/css/bundle";

import "./src/styles/style.css";
import "./src/styles/responsive.css";
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/themes/prism-solarizedlight.css';
import "prismjs/themes/prism.css";

import Prism from "prismjs";
import "prismjs/components/prism-csharp";

export const onRouteUpdate = () => {
  Prism.highlightAll();
};

